<template>
  <div class="home">
    <!-- 头部导航 -->
    <div class="nav" :style="!navBgShow ? 'backgroundColor: transparent;' : 'backgroundColor:#ffffff'">
      <div class="nav-fixed">
        <div class="logo" v-if="false">
          <img src="../assets/logo@3x.db1a3a9.png" alt="">
          <span>让内容运营更高效</span>

        </div>
        <div class="nav-right">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
            v-if="false">
            <el-menu-item index="1" style="border: none;">首页</el-menu-item>
            <el-menu-item index="2" style="border: none;">价格</el-menu-item>
            <el-menu-item index="3" style="border: none;">关于我们</el-menu-item>

            <el-submenu index="5" style="border: none;">
              <template slot="title">下载真琦</template>
              <el-menu-item index="5-1">Windows下载</el-menu-item>
              <el-menu-item index="5-2">MacOS下载</el-menu-item>

            </el-submenu>

            <el-menu-item index="7" style="border: none;">使用指南</el-menu-item>
            <el-menu-item index="8" style="border: none;">登录</el-menu-item>
          </el-menu>
        </div>
        <div class="use" v-if="false">
          <el-button round>免费使用</el-button>
        </div>
      </div>
    </div>
    <!-- banner -->
    <div class="recommend">
      <div class="widths ">
        <div class="bannert-conter">

          <div class="title">
            <img src="../assets/标题.dbb2e24.png" width="557" height="94" alt="">
          </div>
          <div class="download">
            <el-button class="btn left" @click="downFun">免费下载使用</el-button>
            <el-button class="btn" style="color:#EB2A39;background-color:#fddee1;"
            @click="goToUrl('https://dkai.dingkai.online:7001/#/')">账号登录</el-button>
          </div>
          <div class="versions flex">
            <span>更新：2024-12-20</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 持续服务 -->
    <div class="function relative" v-if="false">
      <div class="widths">
        <img width="187" src="../assets/home/元素1@3x.ce72072.png" class="absolute" style="right: 0; top: 0px" alt="">
        <p class="continue">持续服务，打造高效一站式服务平台</p>
        <div class="tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="一键多发" name="first">
              <img width="100%" src="../assets/home/电脑@3x.c0d5d94.png" alt="">
              <img src="../assets/home/oneKeyPublish.c6679fa.gif" class="imgContent" alt="">
            </el-tab-pane>
            <el-tab-pane label="多账号管理" name="second">
              <img width="100%" src="../assets/home/电脑@3x.c0d5d94.png" alt="">
              <img src="../assets/home/accountManage.08d9495.gif" class="imgContent" alt="">
            </el-tab-pane>
            <el-tab-pane label="数据分析" name="third"> <img width="100%" src="../assets/home/电脑@3x.c0d5d94.png" alt="">
              <img src="../assets/home/dataAnalysis.83950e1.gif" class="imgContent" alt="">
            </el-tab-pane>
            <el-tab-pane label="原创检测" name="fourth"> <img width="100%" src="../assets/home/电脑@3x.c0d5d94.png" alt="">
              <img src="../assets/home/originalTest.65f3cf6.gif" class="imgContent" alt="">
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

    </div>
    <!-- 多平台 -->
    <div class="platform-list relative">
      <div class="widths">
        <img width="187" src="../assets/home/元素2@3x.425cedd.png" class="absolute" style="left: 0;bottom: -291px;" alt="">
        <div class="list">
          <p class="title">多平台发布，多账号管理</p>
          <div class="fspan">
            自媒体工具支持40+自媒体账号一键发布，60+账号管理，可同时添加500+个账号
          </div>
          <div class="ant-row">
            <el-row :gutter="12">
              <el-col :span="2" v-for="(item, index) in icons" :key="index">
                <div class="grid-content">
                  <img :src="item.icon" alt="" width="42" height="42">
                  <span style="color: rgb(140, 140, 140);font-size: 12px;">{{ item.name }}</span>
                </div>
              </el-col>
            </el-row>
            <div style="color: #676767;text-align: center;">持续更新中...</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 蚁小二八大特色功能 -->
    <div class="special">
      <div class="container widths">
        <p class="title">特色功能</p>
        <div class="list">

          <div class="grids">
            <div class="grid-content" v-for="(item, index) in feature" :key="index">
              <div class="item">
                <img :src="item.icon" alt="" width="32">
                <p class="mt">{{ item.name }}</p>
                <div class="f15" style="color: rgba(38, 38, 38, 0.85)">
                  <span style="text-align: justify ">{{ item.content }}</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- 合作服务 -->
    <div class="open-platform">
      <div class="container widths">
        <p class="title">合作&服务</p>
        <div class="list">
          <myTouchNav :miniLists="miniList"></myTouchNav>
        </div>
      </div>

    </div>
    <!-- 价格 -->
    <div class="price" id="price" ref="billinfo" v-if="false">
      <div class="container widths">
        <p class="title">价格</p>
        <div class="list">
          <priceList :priceLists="priceList" @onPurchases="onPurchase"></priceList>
        </div>
      </div>
    </div>
    <!-- 他们都在用 -->
    <div class="are-using">
      <div class="container widths">
        <p class="title">他们都在用</p>
      </div>
      <div class="swiper-container">
        <div class="scroll-block">
          <div class="scroll-item">
            <img src="../assets/logo-min.bfc774a.png" alt="">
            <img src="../assets/logo-min.bfc774a.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 内容运营 -->
    <div class="app-foote">
      <div class="footer">
        <div class="widths">

          <p class="title">
            让内容运营更高效
          </p>
          <div class="mt">开启高效运营时代</div>
          <el-button class="btn" @click="downFun">免费下载使用</el-button>
          <div class="jsb flex">
            <img src="../assets/home/icon/科大讯飞.888ab04.png" alt="" height="43">
            <img src="../assets/home/icon/中国搜索.0e26089.png" alt="" height="43">
            <img src="../assets/home/icon/开普云.d7d1289.png" alt="" height="43">
            <img src="../assets/home/icon/两面针.cdc8122.png" alt="" height="43">
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="widths">
          <div class="line"></div>
          <div class="funt flex">
            <div class="fc flex">
              <img src="../assets/home/24小时@3x.37e2b7f.png" width="42" alt="">
              <span class="mt">7*24小时运维服务</span>
            </div>
            <div class="fc flex">
              <img src="../assets/home/24小时@3x.37e2b7f.png" width="42" alt="">
              <span class="mt">7*24小时运维服务</span>
            </div>
            <div class="fc flex">
              <img src="../assets/home/24小时@3x.37e2b7f.png" width="42" alt="">
              <span class="mt">7*24小时运维服务</span>
            </div>
            <div class="fc flex">
              <img src="../assets/home/24小时@3x.37e2b7f.png" width="42" alt="">
              <span class="mt">7*24小时运维服务</span>
            </div>

          </div>
          <div class="line"></div>
          <div class="content flex" v-if="false">
            <div class="logo">
              <img src="../assets/logo839.png" alt="" width="108">
            </div>
            <div class="autos">
              <span>真琦</span>
              <a href="">
                <span>关于我们</span>
              </a>
              <div class="mts">
                <span>客服电话</span>
                <span>15356103840</span>
              </div>
            </div>
            <div class="autos">
              <span>友情链接</span>
              <a href="http://www.gcjlkj.com" target="_blank">
                <span>真琦科技</span>
              </a>
            </div>
            <div class="contact">
              <span class="wi">关注公众号</span>
              <img src="../assets/home/客服微信.png" alt="" width="114" height="114" title="真琦科技">
            </div>
            <div class="contact">
              <span class="wi">添加客服微信</span>
              <img src="../assets/home/客服微信.png" alt="" width="114" height="114" title="真琦科技">
            </div>
          </div>
          <div class="company">
            <a href="http://www.dingkai.online/" target="_blank" v-if="false">河南省鼎开数字科技有限公司 </a>
            <span> 版权所有 Copyright © 2021. All rights reserved </span>
            <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2024084774号 </a>
          </div>
        </div>
      </div>

    </div>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import myTouchNav from "@/components/myTouchNav.vue";
import priceList from "@/components/priceList.vue";
export default {
  name: "Home",
  components: {
    myTouchNav,
    priceList
  },
  data() {
    return {
      activeIndex: "", //头部导航
      activeName: "first", //页面导航
      navBgShow: false,
      icons: [
        {
          icon: require("../assets/home/icon/1.png"),
          name: "头条号",
        },
        {
          icon: require("../assets/home/icon/2.png"),
          name: "百家号",
        },
        {
          icon: require("../assets/home/icon/3.png"),
          name: "企鹅号",
        },
        {
          icon: require("../assets/home/icon/4.png"),
          name: "一点号",
        },
        {
          icon: require("../assets/home/icon/5.png"),
          name: "搜狐号",
        },
        {
          icon: require("../assets/home/icon/6.png"),
          name: "大鱼号",
        },
        {
          icon: require("../assets/home/icon/7.png"),
          name: "网易号",
        },
        {
          icon: require("../assets/home/icon/8.png"),
          name: "趣头条",
        },
        {
          icon: require("../assets/home/icon/9.png"),
          name: "东方号",
        },
        {
          icon: require("../assets/home/icon/10.png"),
          name: "新浪看点",
        },
        {
          icon: require("../assets/home/icon/11.png"),
          name: "快传号",
        },
        {
          icon: require("../assets/home/icon/12.png"),
          name: "大风号",
        },
        {
          icon: require("../assets/home/icon/13.png"),
          name: "爱奇艺号",
        },
        {
          icon: require("../assets/home/icon/14.png"),
          name: "秒拍",
        },
        {
          icon: require("../assets/home/icon/15.png"),
          name: "哔哩哔哩",
        },
        {
          icon: require("../assets/home/icon/16.png"),
          name: "优酷",
        },
        {
          icon: require("../assets/home/icon/17.png"),
          name: "搜狐视频",
        },
        {
          icon: require("../assets/home/icon/18.png"),
          name: "惠头条",
        },
        {
          icon: require("../assets/home/icon/19.png"),
          name: "抖音",
        },
        {
          icon: require("../assets/home/icon/20.png"),
          name: "微信公众号",
        },
        {
          icon: require("../assets/home/icon/21.png"),
          name: "新浪微博",
        },
        {
          icon: require("../assets/home/icon/23.png"),
          name: "简书号",
        },
        {
          icon: require("../assets/home/icon/24.png"),
          name: "快手",
        },
        {
          icon: require("../assets/home/icon/26.png"),
          name: "腾讯视频",
        },
        {
          icon: require("../assets/home/icon/27.png"),
          name: "美拍",
        },
        {
          icon: require("../assets/home/icon/29.png"),
          name: "知乎",
        },
        {
          icon: require("../assets/home/icon/30.png"),
          name: "腾讯微视",
        },
        {
          icon: require("../assets/home/icon/31.png"),
          name: "小红书",
        },
        {
          icon: require("../assets/home/icon/32.png"),
          name: "微信视频号",
        },
        {
          icon: require("../assets/home/icon/33.png"),
          name: "蜂网",
        },
        {
          icon: require("../assets/home/icon/34.png"),
          name: "芒果TV",
        },
        {
          icon: require("../assets/home/icon/35.png"),
          name: "喜马拉雅",
        },
        {
          icon: require("../assets/home/icon/36.png"),
          name: "虎牙视频",
        },
        {
          icon: require("../assets/home/icon/37.png"),
          name: "快看点",
        },

        {
          icon: require("../assets/home/icon/39.png"),
          name: "美柚",
        },
        {
          icon: require("../assets/home/icon/40.png"),
          name: "皮皮虾",
        },
        {
          icon: require("../assets/home/icon/41.png"),
          name: "全民小视频",
        },
        {
          icon: require("../assets/home/icon/42.png"),
          name: "wifi万能钥匙",
        },
        {
          icon: require("../assets/home/icon/43.png"),
          name: "爆米花视频号",
        },
        {
          icon: require("../assets/home/icon/45.png"),
          name: "宝宝知道",
        },
        {
          icon: require("../assets/home/icon/46.png"),
          name: "百度知道",
        },
        {
          icon: require("../assets/home/icon/47.png"),
          name: "宝树号",
        },
        {
          icon: require("../assets/home/icon/52.png"),
          name: "太平洋号",
        },
        {
          icon: require("../assets/home/icon/53.png"),
          name: "易车号",
        },
        {
          icon: require("../assets/home/icon/54.png"),
          name: "汽车头条",
        },
        {
          icon: require("../assets/home/icon/55.png"),
          name: "豆瓣",
        },
        {
          icon: require("../assets/home/icon/59.png"),
          name: "车家号",
        },
        {
          icon: require("../assets/home/icon/60.png"),
          name: "斗鱼",
        },
        {
          icon: require("../assets/home/icon/72.png"),
          name: "AcFun",
        },
        {
          icon: require("../assets/home/icon/74.png"),
          name: "雪球号",
        },
        {
          icon: require("../assets/home/icon/77.png"),
          name: "财富号",
        },
        {
          icon: require("../assets/home/icon/79.png"),
          name: "黑猫投诉",
        },
        {
          icon: require("../assets/home/icon/80.png"),
          name: "腾讯内容开放平台",
        },
        {
          icon: require("../assets/home/icon/81.png"),
          name: "老司机",
        },
        {
          icon: require("../assets/home/icon/90.png"),
          name: "西瓜视频",
        },
        {
          icon: require("../assets/home/icon/91.png"),
          name: "多多视频",
        },
        {
          icon: require("../assets/home/icon/211.png"),
          name: "微博头条文章",
        },
      ],
      feature: [
        {
          icon: require("../assets/home/icon/1.be4d434.png"),
          name: "一键发布",
          content:
            "60+自媒体平台图文、视频发布参数一键配置，轻松实现3分钟一键发布",
        },
        {
          icon: require("../assets/home/icon/2.9073ab0.png"),
          name: "账号管理",
          content: "500+账号管理，根据领域、分组、类型账号实时切换、方便管理",
        },
        {
          icon: require("../assets/home/icon/3.62166c2.png"),
          name: "微头条/动态备份",
          content:
            "支持动态一键发布至微头条、微博、百家号动态、知乎想法，一点号短内容。",
        },
        {
          icon: require("../assets/home/icon/4.1122c69.png"),
          name: "数据统计",
          content:
            "支持按内容、部门员工、平台、账号多个维度数据进行，筛选查询，数据更新延时不超过4小时",
        },
        {
          icon: require("../assets/home/icon/5.7897972.png"),
          name: "收益统计",
          content: "统计发文收益，随时反馈数据（支持屏蔽成员收益）",
        },
        {
          icon: require("../assets/home/icon/6.270de07.png"),
          name: "原创质量检测",
          content:
            "检测文章原创度、文本纠错、标题、图片、违禁词、错别字、政治敏感等多元化检测项目，让你的文章更安全，内容有价值",
        },
        {
          icon: require("../assets/home/icon/8.b456933.png"),
          name: "智能创作",
          content: "在线搜图、标题助手、热点发现，为您的创作赋能增彩",
        },
        {
          icon: require("../assets/home/icon/9.cf85b5f.png"),
          name: "权限设置",
          content:
            "支持自定义岗位权益设置，支持二级管理权限，独有的内容审核发稿权限",
        },
      ],
      miniList: [
        {
          icon: require("../assets/home/icon/OEM系统定制.09fd51a.png"),
          name: "OEM系统定制",
          tags: ["按期交付", "售后服务"],
          content: {
            icon: require("../assets/home/icon/OEM系统定制选中.192dc65.png"),
            name: "OEM系统定制",
            content:
              "为党政机关、媒体、企业及开发者等提供“全面、实时、准确”的新媒体内容大数据定制服务；可根据您的需求，提供蚁小二全平台功能需求定制、云分发服务",
            tags: ["按期交付", "售后服务"],
            href: "#",
          },
          miniShow: true,
        },
        {
          icon: require("../assets/home/icon/代分发服务.23f9022.png"),
          name: "代分发业务",
          content: {
            icon: require("../assets/home/icon/代分发服务选中.65a1ad5.png"),
            name: "代分发业务",
            content:
              "为用户提供专业的图文/视频代分发服务，帮助解决多平台多账号发布耗时的难题",
            tags: ["视频剪辑", "文章代分发", "视频代分发", "公众号代运营"],
            href: "#",
          },
          tags: ["视频剪辑", "文章代发", "视频代发", "公众号代运营"],
          miniShow: false,
        },
        {
          icon: require("../assets/home/icon/需求定制开发.72a83d3.png"),
          name: "需求定制开发",
          content: {
            icon: require("../assets/home/icon/需求定制开发选中.00b968c.png"),
            name: "需求定制开发",
            content: "可根据您的需求，提供全平台功能需求定制",
            tags: ["专业", "高效", "灵活定制"],
            href: "#",
            miniShow: false,
          },
          tags: ["专业", "高效", "灵活定制"],
          miniShow: false,
        },
      ],
      priceList: [
        {
          title: "免费版", //标题
          fu: "适合个人/工作室", //副标题
          bags: 0, //是否添加背景
          price: "0", //价格
          pricehref: "", //购买地址
          teamNum: 1, //团队人数
          accountNum: 5, //账号管理
          labelNum: 5, //账号中心打开标签数
          earnings: false, //屏蔽账号收益
          issueSpeed: 3, //分发速度-图文
          issueImg: "5篇/天", //图文/视频分发
          issueTask: 3, //多任务一键发布
          issueDraft: true, //同步至平台草稿箱
          issueTitle: false, //标题助手
          issueHotspot: false, //热点发现
          issueOnLine: false, //在线搜图
          issueDetection: "无限制", //质量检测
          issueGrouping: true, //账号分组
          issueTheHeavy: true, //防重复发布机制
          issueLabel: true, //各平台独立标签
          issueTitles: true, //各平台独立标题
          issueTiming: true, //定时发布
          issueOriginal: true, //声明原创/首发
          issueRecordImg: true, //记录图文原创
          issueRecordvideo: true, //记录视频原创
          dataPlatform: false, //平台数据
          dataStaff: false, //员工数据
          dataAccount: false, //账号数据
          dataContent: false, //内容统计
          satisfy: false, //需求优先满足
        },
        {
          title: "分发版(月付)", //标题
          fu: "适合个人/工作室", //副标题
          bags: "1", //是否添加背景
          price: "48", //价格
          pricehref: "", //购买地址
          teamNum: 2, //团队人数
          accountNum: 20, //账号管理
          labelNum: 8, //账号中心打开标签数
          earnings: true, //屏蔽账号收益
          issueSpeed: 10, //分发速度-图文
          issueImg: "无限制", //图文/视频分发
          issueTask: 10, //多任务一键发布
          issueDraft: true, //同步至平台草稿箱
          issueTitle: true, //标题助手
          issueHotspot: true, //热点发现
          issueOnLine: true, //在线搜图
          issueDetection: "无限制", //质量检测
          issueGrouping: true, //账号分组
          issueTheHeavy: true, //防重复发布机制
          issueLabel: true, //各平台独立标签
          issueTitles: true, //各平台独立标题
          issueTiming: true, //定时发布
          issueOriginal: true, //声明原创/首发
          issueRecordImg: true, //记录图文原创
          issueRecordvideo: true, //记录视频原创
          dataPlatform: true, //平台数据
          dataStaff: true, //员工数据
          dataAccount: true, //账号数据
          dataContent: true, //内容统计
          satisfy: true, //需求优先满足
        },
        {
          title: "分发版(年付)", //标题
          fu: "适合个人/工作室", //副标题
          fus: "576/年 优惠278元",
          bags: "2", //是否添加背景
          price: "298", //价格
          pricehref: "", //购买地址
          teamNum: 5, //团队人数
          accountNum: 50, //账号管理
          labelNum: 8, //账号中心打开标签数
          earnings: true, //屏蔽账号收益
          issueSpeed: 10, //分发速度-图文
          issueImg: "无限制", //图文/视频分发
          issueTask: 10, //多任务一键发布
          issueDraft: true, //同步至平台草稿箱
          issueTitle: true, //标题助手
          issueHotspot: true, //热点发现
          issueOnLine: true, //在线搜图
          issueDetection: "无限制", //质量检测
          issueGrouping: true, //账号分组
          issueTheHeavy: true, //防重复发布机制
          issueLabel: true, //各平台独立标签
          issueTitles: true, //各平台独立标题
          issueTiming: true, //定时发布
          issueOriginal: true, //声明原创/首发
          issueRecordImg: true, //记录图文原创
          issueRecordvideo: true, //记录视频原创
          dataPlatform: true, //平台数据
          dataStaff: true, //员工数据
          dataAccount: true, //账号数据
          dataContent: true, //内容统计
          satisfy: true, //需求优先满足
        },
      ],
    };
  },
  methods: {
    goToUrl(url){
      window.location.href = url;
    },
    downFun() {
      let a = document.createElement('a');
      a.href = 'https://dkai.dingkai.online:8000/download/小蚂蚁账号管家.exe';
      a.download = '文件名称';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    //立即购买
    // 1代表月费
    // 2代码年费
    onPurchase(val) {
      console.log(val)
    },
    // 持续服务
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 导航栏点击事件
    handleSelect(key, keyPath) {
      if (key == 1) {
        const returnEle = document.querySelector(".home"); //productId是将要跳转区域的id
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
      } else if (key == 2) {
        const returnEle = document.querySelector("#price"); //productId是将要跳转区域的id
        if (!!returnEle) {
          returnEle.scrollIntoView(true); // true 是默认的
        }
      }

      // console.log(keyPath);
    },
    // 获取页面的高度
    handleScroll(val) {
      let scrollTop =
        document.documentElement && document.documentElement.scrollTop;
      if (scrollTop > 50) {
        this.navBgShow = true;
      } else {
        this.navBgShow = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
@import url("../style/Home.less");
</style> 
